<template>
  <section
    id="webhooks"
    :item="verCambio(this.$store.state.appConfig.projecto.id)"
  >
    <b-col cols="14">
      <b-card>
        <div>
          <h4 class="my-1">Webhooks</h4>
        </div>
        <div class="mt-1">
          <span>Configure aquí la URL de consulta de los webhooks de </span>
          <span class="font-weight-bolder">{{
            $store.state.appConfig.projecto.name
          }}</span>

          <!-- <div id="loading-bg" v-if="loading"> -->
          <!-- <div class="loading"  v-if="loading">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div> -->
        </div>
        <!-- </div> -->

        <div>
          <!-- Header: Webhooks -->
          <div v-if="!$store.state.appConfig.loading">
            <!-- Form:  -->
            <b-form class="mt-2">
              <b-row>
                <!-- Field: URL -->
                <b-col cols="12" md="6" lg="6">
                  <b-form-group label="URL Request" label-for="url-request">
                    <b-form-input id="url-request" v-model="urlRequest" />
                  </b-form-group>

                  <div class="row margen-medio-pago">
                    <b-form-checkbox
                      v-for="(event, index) in eventsOptions"
                      :key="index"
                      v-model="events"
                      :value="event.value"
                      class="custom-control-info col-6 mt-2"
                    >
                      {{ event.text }}
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
            </b-form>

            <!-- botones -->
            <div class="row align-items-center mt-5">
              <div class="col justify-content-end">
                <div style="display: flex">
                  <div style="margin-left: auto">
                    <b-button
                      variant="outline-primary"
                      class="mr-1 ancho-button"
                      @click="modalCancelarShow = !modalCancelarShow"
                      v-b-modal.modal-Cancelar
                    >
                      Cancelar
                    </b-button>

                    <b-button
                      variant="primary"
                      class="shadow-none ancho-button"
                      @click="() => handleSave()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>

    <b-modal
      id="modal-Cancelar"
      body-bg-variant="light-warning"
      v-model="modalCancelarShow"
      hide-footer
      hide-header
      centered
      size="sm"
      ref="modal-Cancelar"
    >
      <label id="label-confirmacion-cancelar">
        ¿ Seguro que quieres cancelar?
      </label>
      <b-row>
        <b-col>
          <b-button
            variant="warning"
            class="mr-1 modal-button-size shadow-none"
            @click="hideCancelarModal('no')"
          >
            No
          </b-button>

          <b-button
            variant="outline-warning"
            class="modal-button-size shadow-none"
            id="agregar-button-modal"
            @click="hideCancelarModal('si')"
          >
            Sí
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import axios from 'axios';
import { backendUrl } from '@/config';
import moment from 'moment';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  mounted() {
    this.getWebHook(this.$store.state.appConfig.projecto.id);
  },
  data() {
    return {
      edit: false,
      modalCancelarShow: false,
      dir: 'ltr',
      allProduct: [],
      urlRequest: '',
      events: [],
      idProduct: null,
    };
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    BFormCheckbox,
    ToastificationContent,
  },
  setup() {
    const eventsOptions = [
      { text: "Pago Único", value: "Pago Único" },
      { text: "Pago Recurrente", value: "Pago Recurrente" },
      { text: "Reembolso", value: "Reembolso" },
      { text: "Cancelación de Suscripción", value: "Cancelación Recurrente" },
    ];

    return {
      eventsOptions,
    };
  },
  methods: {
    verCambio(id) {
      if (this.idProduct === id) {
        //this.loading = false;
        this.$store.commit('appConfig/changeLoading', false);
        return;
      } else {
        this.$store.commit('appConfig/changeLoading', true);
        //this.loading = true;
        this.getWebHook(id);
        return;
      }
    },
    async getWebHook(id) {
      const token = localStorage.getItem('token');

      this.idProduct = this.$store.state.appConfig.projecto.id;

      var config = {
        method: 'get',
        url: `${backendUrl}/web-hook/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const resp = await axios(config);

        if (resp.data.links.length === 0) {
          this.edit = false;
          this.urlRequest = '';
          this.events = [];
          //this.loading = false;
          this.$store.commit('appConfig/changeLoading', false);
          return;
        } else {
          this.events = [];
          this.urlRequest = resp.data.links[0].url_api;
          resp.data.links[0].single_payment === 1 &&
            this.events.push('Pago Único');
          resp.data.links[0].recurring_payment === 1 &&
            this.events.push('Pago Recurrente');
          resp.data.links[0].single_payment_refund === 1 &&
            this.events.push('Reembolso');
          resp.data.links[0].single_payment_cancellation === 1 &&
            this.events.push('Cancelación Pago Único');
          resp.data.links[0].recurring_payment_cancellation === 1 &&
            this.events.push('Cancelación Recurrente');
          this.edit = true;
          this.$store.commit('appConfig/changeLoading', false);
          //this.loading = false;
          return;
        }
      } catch (error) {
      }
    },
    async handleSave() {
      this.$store.commit('appConfig/changeLoading', true);

      const token = localStorage.getItem('token');

      if (this.edit) {
        var data = JSON.stringify({
          url_api: this.urlRequest,
          single_payment: this.events.includes('Pago Único') ? 1 : 0,
          recurring_payment: this.events.includes('Pago Recurrente') ? 1 : 0,
          single_payment_refund: this.events.includes('Reembolso') ? 1 : 0,
          recurring_payment_refund: 0,
          single_payment_cancellation: 0,
          recurring_payment_cancellation: this.events.includes(
            'Cancelación Recurrente'
          )
            ? 1
            : 0,
        });

        var config = {
          method: 'post',
          url: `${backendUrl}/web-hook/edit/${this.$store.state.appConfig.projecto.id}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: data,
        };
        try {
          const resp = await axios(config);
          this.showWebhookEditadoExitosoToast('success');
          this.$store.commit('appConfig/changeLoading', false);

          return resp;
        } catch (error) {
          this.$store.commit('appConfig/changeLoading', false);

          this.showWebhookEditadoErrorToast('danger');
        }
      } else {
        var data = JSON.stringify({
          date: moment().format(),
          url_api: this.urlRequest,
          id_event: 2,
          id_project: this.$store.state.appConfig.projecto.id,
          single_payment: this.events.includes('Pago Único') ? 1 : 0,
          recurring_payment: this.events.includes('Pago Recurrente') ? 1 : 0,
          single_payment_refund: this.events.includes('Reembolso') ? 1 : 0,
          recurring_payment_refund: 0,
          single_payment_cancellation: 0,
          recurring_payment_cancellation: this.events.includes(
            'Cancelación Recurrente'
          )
            ? 1
            : 0,
        });

        var config = {
          method: 'post',
          url: `${backendUrl}/web-hook/add`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: data,
        };
        try {
          const resp = await axios(config);
          this.showWebhookEditadoExitosoToast('success');
          return resp;
        } catch (error) {
          this.showWebhookEditadoErrorToast('danger');
        }
      }
    },
    showWebhookEditadoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Se han editado los Webhooks exitosamente!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    showWebhookEditadoErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al editar los Webhooks',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    hideCancelarModal(tipo) {
      this.$refs['modal-Cancelar'].hide();
      if (tipo == 'si') {
        this.getWebHook(this.$store.state.appConfig.projecto.id);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#selectProductos .vs__selected-options .vs__selected {
  background: $info;
  border-radius: 20px;
}

.ancho-button {
  width: 146.22px;
}

.modal-button-size {
  width: 166px;
}

#label-confirmacion-cancelar {
  margin-left: 22%;
  margin-bottom: 16px;
  color: $warning !important;
  font-weight: bold;
  font-size: 14px;
}

.margen-medio-pago {
  margin-left: 1px;
}
</style>
